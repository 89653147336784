import React, { lazy, Suspense } from "react"

// import { TabContext } from "../contexts/tab-context"
// import Accordion from "../components/blocks/accordion"
// import Calendar from "../images/svgs/calendar.svg"
// import EventList from "../components/events/list"
// import LinkExternal from "../components/blocks/link-external"
// import Seo from "../components/seo"
// import Seo from "../components/seo"
//import { StaticImage } from "gatsby-plugin-image"
//import ArrowBullet from "../images/svgs/arrow-bullet.svg"
//import ArrowDownStroke from "../images/svgs/arrow-down-stroke.svg"
//import Button from "../components/button"
//import Factbox from "../components/factbox"
//import Icon from "../components/icon"
//import PersonList from "../components/persons/list"
//import Slideshow from "../components/Slideshow"

import "../css/gutenberg.css"
import "twin.macro"
import { graphql } from "gatsby"
import Blocks from "../components/blocks"
import ContactAnimation from "../components/animations/contact"
import Container from "../components/container"
import extendBlocksWithSlugs from "../helpers/extend-blocks-with-slugs"
import Indent from "../components/indent"
import Instagram from "../components/social/instagram"
import Spacing from "../components/spacing"
import Linkedin from "../components/social/linkedin"

const Animation = lazy(() => import("../components/animation"))

const HomeTemplate = ({
  data: { page, events, members, protagonists, seo },
}) => {
  const blocks = page.blocks?.blocks || []
  //const blocks = []
  const [filteredBlocks] = extendBlocksWithSlugs(blocks)
  // events = events.edges.map(({ node }) => node)

  // const eventsOnFirstDay = events.filter(
  //   event =>
  //     moment(event.startDate).format("DD.MM.YYYY") ===
  //     moment("2022-12-05").format("DD.MM.YYYY")
  // )
  // const eventsOnLastDay = events.filter(
  //   event =>
  //     moment(event.startDate).format("DD.MM.YYYY") ===
  //     moment("2022-12-06").format("DD.MM.YYYY")
  // )

  protagonists = protagonists.edges.map(({ node }) => node)
  // const fallbackSmWidth = 767
  // const fallbackSmHeight = 1156
  // const fallbackLgWidth = 2110
  // const fallbackLgHeight = 878
  /*const anchors = [
    // { id: "highlights", label: "Highlights" },
    { id: "aktive", label: "Aktive auf der Bühne" },
    // { id: "programm", label: "Programm" },
    { id: "anmeldung", label: "Anmeldung" },
    { id: "faq", label: "FAQ" },
    { id: "kontakt", label: "Kontakt" },
  ]*/

  const anchors = []

  // const { showFirstDay, setShowFirstDay } = useContext(TabContext)

  return (
    <>
      <div>
        <div tw="grid grid-cols-1 md:grid-cols-2">
          <div tw="col-span-1 flex justify-end bg-sand-500">
            <div tw="flex w-full max-w-screen-md pl-4 sm:pl-10 lg:pl-20">
              <div tw="flex max-h-screen w-full flex-col justify-between">
                <div style={{ minHeight: 140 }}></div>
                <div tw="mb-8 w-full">
                  <div tw="mb-2 font-regular text-xl lg:mb-4 lg:text-3xl">
                    2. Gleichstellungstag
                  </div>
                  <div tw="text-5xl font-bold lg:text-7xl">zusammen:</div>
                  <div tw="mb-3 text-5xl font-bold md:mb-7 lg:ml-30 lg:text-7xl xl:ml-40">
                    wirken
                  </div>
                  <div tw="mb-7 max-w-md font-regular text-xl lg:text-3xl">
                    Wandel wird mit Gleichstellung gemacht.
                  </div>
                  <div tw="mb-7 max-w-md font-bold text-base lg:text-3xl">
                    <span tw="bg-lilac-300 p-2">5. November 2024</span><br />
                    <span tw="bg-lilac-300 p-2">bcc Berlin Congress Center</span>
                  </div>
                </div>
                <div tw="mb-8 w-auto">
                  <div tw="text-xl lg:text-3xl font-bold">#EsIstZeit<span tw="ml-5">#ZusammenWirken</span></div>
                  <div tw="flex">
                    <Linkedin />
                    <Instagram />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div tw="col-span-1">
            <div tw="aspect-ratio aspect-ratio-4/3 md:aspect-ratio-2/3 xl:aspect-ratio-4/5">
              <Suspense>
                <Animation />
              </Suspense>
            </div>
          </div>
        </div>

        {/* <div
          tw="aspect-ratio aspect-ratio-952/530"
          role="img"
          aria-label="Unsere Gesellschaft braucht Gleichstellung. Es ist Zeit"
        >
          <div
            tw="aspect-ratio-item keyvisual-background-vertical md:keyvisual-background-horizontal"
            aria-hidden="true"
          >
            <StaticImage
              src="../../content/assets/home-animation-fallback-sm.png"
              alt="Unsere Gesellschaft braucht Gleichstellung. Es ist Zeit"
              tw="hidden w-full motion-reduce:block motion-reduce:md:hidden"
              width={fallbackSmWidth}
              height={fallbackSmHeight}
            />
            <StaticImage
              src="../../content/assets/home-animation-fallback-lg.png"
              alt="Unsere Gesellschaft braucht Gleichstellung. Es ist Zeit"
              tw="hidden w-full md:motion-reduce:block "
              width={fallbackLgWidth}
              height={fallbackLgHeight}
            />
            <Suspense>
              <Animation />
            </Suspense>
          </div>
        </div> */}

        {/*
        <section id="livestream" tw="bg-lilac-300">
          <Spacing space="extralarge" padding>
            <Container>
              <div tw="mb-11 text-3xl font-bold md:text-4xl">
                Livestream 1. Gleichstellungstag
              </div>
              <Indent>
                <p tw="pb-8 text-xl font-bold">
                  Der Livestream startet am 6. Dezember um 13.30 Uhr.
                  <br />
                  Informationen zum Programmablauf finden Sie auf dieser
                  Website.
                </p>
                <div tw="overflow-hidden aspect-ratio aspect-ratio-w-16 aspect-ratio-h-9">
                  <StaticImage
                    src="../images/livestream-geduld.jpg"
                    alt="Livestream zum 1. Gleichstellungstag"
                    tw="object-contain aspect-ratio-item"
                    load="lazy"
                  />
                  <iframe
                    src="https://live.m-events.com/gleich22/kanal1.html"
                    tw="w-full overflow-hidden border-none aspect-ratio-item"
                    loading="lazy"
                    scrolling="no"
                    title="Livestream 1. Gleichstellungstag"
                  ></iframe>
                </div>
              </Indent>
            </Container>
          </Spacing>
        </section>
        */}
        <section id="savethedate" tw="">
          <Spacing space="extralarge" padding>
            <Container>
              {/*<div tw="mb-12 mt-8 flex flex-row flex-wrap md:mb-16">
                {anchors.map((item, index) => (
                  <a
                    href={`#${item.id}`}
                    tw="mr-5 mb-5 inline-block bg-lilac-500 px-7 py-2 font-bold tracking-widest text-anthracite-500 no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
                    key={`anchor-${index}`}
                  >
                    {item.label}
                  </a>
                ))}
              </div>*/}
              <h1 tw="mb-11 text-3xl font-bold md:text-4xl">
                Save the Date!
              </h1>
              <Indent>
              <p tw="pb-8 text-xl">Unter dem Motto „zusammen:wirken – Wandel wird mit Gleichstellung gemacht.“ findet der 2. bundesweite Gleichstellungstag am 5. November 2024 in Berlin statt.</p>
              <p tw="pb-8 text-xl">Das eintägige Kongress- und Messeformat wird sich mit zentralen gesellschaftlichen Veränderungsprozessen und ihren Wechselwirkungen auf Geschlechtergerechtigkeit beschäftigen. Migrationsbewegungen, Flucht und Asyl oder Geschlechtervielfalt sollen ebenso zum Thema werden wie Parität, neue Arbeitszeit-/Lebenszeitmodelle, ökonomische Gleichstellung oder der Umgang mit erstarkendem Antifeminismus. Denn in Zeiten multipler Krisen müssen Transformationsprozesse aktiv gestaltet werden: sozial und ökologisch, gleichstellungsorientiert und demokratisch.</p>
              <p tw="pb-8 text-xl">Der Fachkongress hält etwa 30 Workshops, Foren und Diskussionsrunden bereit, die überwiegend von Verbänden, Vereinen, Initiativen und Organisationen gestaltet werden. Auf der parallel stattfindenden Fachmesse haben rund 40 Organisationen die Möglichkeit, sich und ihre Arbeit vorzustellen und mit anderen Akteur*innen in den Austausch zu kommen. Abgerundet wird die Veranstaltung von einem Rahmenprogramm. Für alle, die nicht in Berlin dabei sein können, wird das Programm auf der Hauptbühne am 5. November live im Internet gestreamt.</p>
              <p tw="pb-8 text-xl"></p>
              <p tw="mb-11 text-xl font-bold">
              Weitere detaillierte Informationen zum Programm, zu den Speaker*innen und zur Anmeldung finden Sie ab dem 5. August 2024 auf dieser Seite.
              </p>

              </Indent>
              {/*<div tw="md:mb-20 ">
                <Factbox />
              </div>*/}
            </Container>
          </Spacing>
        </section>

        {/*<section id="rueckblick" tw="bg-sand-500">
          <Spacing space="extralarge" padding>
            <Container>
              <h2 tw="mb-11 text-3xl font-bold md:text-4xl">Rückblick</h2>
              <Indent>
                <Slideshow />
              </Indent>
            </Container>
          </Spacing>
        </section>*/}

        {/*<section id="highlights"></section>
        <section tw="" id="aktive">
          <Spacing space="extralarge" padding>
            <Container>
              <div tw="mb-10 flex items-end  justify-between lg:mb-18">
                <h1 tw="text-3xl font-bold lg:text-5xl">
                  Aktive auf der Bühne
                </h1>
              </div>
              <Indent>
                <PersonList protagonists={protagonists} />
              </Indent>
            </Container>
          </Spacing>
        </section>*/}

        {/* <section id="programm">
          <Spacing padding>
            <Container>
              <div tw="mb-10 lg:mb-18">
                <h1 tw="text-3xl font-bold lg:text-5xl">Programm</h1>
              </div>
              <div tw="mb-11 flex w-full justify-center">
                <button
                  onClick={() => setShowFirstDay(true)}
                  tw="mr-10 h-10 cursor-pointer font-bold hover:underline-bold"
                  css={[showFirstDay && tw`underline-bold`]}
                >
                  5. Dezember 2022
                </button>
                <button
                  onClick={() => setShowFirstDay(false)}
                  tw="h-10 cursor-pointer font-bold hover:underline-bold"
                  css={[!showFirstDay && tw`underline-bold`]}
                >
                  6. Dezember 2022
                </button>
              </div>

              {showFirstDay ? (
                <EventList events={eventsOnFirstDay} />
              ) : (
                <EventList events={eventsOnLastDay} />
              )}
            </Container>
          </Spacing>
        </section> */}

        {/*<section tw="bg-sand-500">
          <Spacing space="extralarge" padding>
            <Container>
              <div tw="mb-11 text-3xl font-bold md:text-4xl">
                Veranstaltungs-Booklet zum 1. Gleichstellungstag
              </div>
              <Indent>
                <p tw="pb-8 text-xl font-bold">
                  Weitere Details zum Programm finden Sie auch in diesem
                  Booklet, das wir als Begleiter durch die beiden
                  Veranstaltungstage konzipiert haben – mit
                  Hintergrundinformationen, einer Workshop-Übersicht und
                  reichlich Platz für digitale Notizen.
                </p>
                <div tw="max-w-md">
                  <figure>
                    <div tw="relative mb-4 bg-sand-500 aspect-ratio aspect-ratio-3/4">
                      <StaticImage
                        src="../images/booklet.jpg"
                        alt="Unser Booklet zum Livestream 1. Gleichstellungstag"
                        tw="object-contain aspect-ratio-item"
                        load="lazy"
                      />
                    </div>
                    <figcaption>
                      <a
                        href="/downloads/booklet.pdf"
                        tw="flex items-start text-lg font-bold lg:text-xl"
                        className="group"
                        download
                      >
                        <Icon
                          svg={ArrowDownStroke}
                          tw="mr-3 mt-1 h-6 w-5 flex-shrink-0 md:mr-4 md:h-6 md:w-5"
                          title="Download"
                        />
                        <span tw="underline-offset-4 group-hover:underline group-hover:decoration-3">
                          {"Booklet "}
                          <span tw="uppercase">(PDF, 2,5 MB)</span>
                        </span>
                      </a>
                    </figcaption>
                  </figure>
                </div>
              </Indent>
            </Container>
          </Spacing>
        </section>*/}

        {/*<section id="anmeldung" tw="bg-lilac-300">
          <Spacing space="extralarge" padding>
            <Container>
              <div tw="mb-10 flex items-end  justify-between lg:mb-11">
                <h1 tw="text-3xl font-bold lg:text-5xl">
                  Informationen zur Anmeldung
                </h1>
              </div>

              <Indent>
                <p tw="mb-10 text-xl font-bold lg:mb-16">
                  Anmeldefrist abgelaufen
                  <br />
                  <br />
                  Herzlichen Dank für Ihr Interesse am Gleichstellungstag.
                  <br />
                  Leider ist die Anmeldefrist für den 1. Gleichstellungstag
                  bereits abgelaufen.
                  <br />
                  Bei Rückfragen wenden Sie sich gerne an
                  <br />
                  <a href="mailto:gleichstellungstag@bundesstiftung-gleichstellung.de">
                    gleichstellungstag@bundesstiftung-gleichstellung.de
                  </a>
                </p>
              </Indent>
              <div tw="mb-11 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2">
                <div tw="bg-sand-500">
                  <div tw="mb-10 p-10 lg:mb-18">
                    <h1 tw="mb-9 text-3xl font-bold lg:text-4xl">
                      Montag, 5.12.2022
                    </h1>
                    <p tw="mb-9 text-xl">
                      Festliche Abendveranstaltung mit Personen aus Politik und
                      Gesellschaft, Vertreter*innen von Verbänden und
                      Institutionen, aber auch Gleichstellungsbeauftragte und
                      Aktivist*innen der Gleichstellungsszene.
                    </p>
                    <ul>
                      <li tw="mb-4 flex items-center text-sm font-bold">
                        <Icon svg={ArrowBullet} tw="mr-3 w-6" />
                        Grußwort zur Eröffnung: Lisa Paus
                      </li>
                      <li tw="mb-4 flex items-center text-sm font-bold">
                        <Icon svg={ArrowBullet} tw="mr-3 w-6" />
                        Paneldiskussion
                      </li>
                      <li tw="mb-4 flex items-center text-sm font-bold">
                        <Icon svg={ArrowBullet} tw="mr-3 w-6" />
                        Spoken Word Performance
                      </li>
                      <li tw="mb-4 flex items-center text-sm font-bold">
                        <Icon svg={ArrowBullet} tw="mr-3 w-6" />
                        Flying Dinner und Get-Together bei Musik
                      </li>
                      <li tw="mb-4 flex items-center text-sm font-bold">
                        <Icon svg={ArrowBullet} tw="mr-3 w-6" />
                        Live-Stream
                      </li>
                    </ul>
                  </div>
                </div>
                <div tw="bg-sand-500">
                  <div tw="mb-10 p-10 lg:mb-18">
                    <h1 tw="mb-9 text-3xl font-bold lg:text-4xl">
                      Dienstag, 6.12.2022
                    </h1>
                    <p tw="mb-9 text-xl">
                      Fachtag mit Workshops und Paneldiskussionen u.a. zu
                      feministischer Führungskultur, Parität in Parlamenten und
                      zu den Gleichstellungsberichten der Bundesregierung
                    </p>
                    <ul>
                      <li tw="mb-4 flex items-center text-sm font-bold">
                        <Icon svg={ArrowBullet} tw="mr-3 w-6" />
                        sieben hybride Workshops
                      </li>
                      <li tw="mb-4 flex items-center text-sm font-bold">
                        <Icon svg={ArrowBullet} tw="mr-3 w-6" />
                        Vorstellung des Arbeitsprogramms 2023
                      </li>
                      <li tw="mb-4 flex items-center text-sm font-bold">
                        <Icon svg={ArrowBullet} tw="mr-3 w-6" />
                        Möglichkeit zur Vernetzung untereinander
                      </li>
                      <li tw="mb-4 flex items-center text-sm font-bold">
                        <Icon svg={ArrowBullet} tw="mr-3 w-6" />
                        internationales Abschlusspanel
                      </li>
                      <li tw="mb-4 flex items-center text-sm font-bold">
                        <Icon svg={ArrowBullet} tw="mr-3 w-6" />
                        Live-Stream
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Container>
          </Spacing>
        </section>*/}

        <section id="faq" tw="bg-sand-500">
          <Spacing space="extralarge" id="second" padding>
            <Container>
              <div tw="mb-10 flex items-end  justify-between lg:mb-18">
                <h1 tw="text-3xl font-bold lg:text-5xl">FAQ</h1>
              </div>
              <Indent>
                <Blocks blocks={filteredBlocks} indent />
              </Indent>
            </Container>
          </Spacing>
        </section>
        <section id="kontakt">
          <div tw="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
            <div tw="">
              <ContactAnimation />
            </div>
            <div tw="flex bg-white md:items-center md:justify-center">
              <div tw="max-w-lg py-10 px-4 sm:px-10 lg:px-20 xl:px-30 ">
                <h3 tw="mb-12 text-3xl font-bold lg:text-4xl">Kontakt</h3>
                <p tw=" mb-6">
                  <b tw="text-2xl font-bold" style={{ fontWeight: "normal" }}>
                    Bundesstiftung Gleichstellung
                  </b>
                  <br /><br />
                  <b tw="text-2xl font-bold" style={{ fontWeight: "normal" }}>
                    Team Presse, Kommunikation und Veranstaltungen
                  </b>
                </p>
                <p tw="text-xl">
                  +49 30 994 0570-26
                  <br />
                  <a
                    href="mailto:gleichstellungstag@bundesstiftung-gleichstellung.de"
                    tw="text-link"
                  >
                    Mail
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export { Head } from "../components/head"

export default HomeTemplate

export const pageQuery = graphql`
  query HomeById {
    site {
      siteMetadata {
        siteUrl
      }
    }
    wp {
      seo {
        schema {
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    seo: wpPage(id: { eq: "cG9zdDoxNzM0" }) {
      ...SeoFragment
    }
    page: wpPage(id: { eq: "cG9zdDoxNzM0" }) {
      id
      nodeType
      title
      uri
      content
      date(formatString: "MMMM DD, YYYY")
      ...BlocksFragment
    }
    members: allWpMember(sort: { fields: member___firstname, order: ASC }) {
      edges {
        node {
          id
          title
          member {
            email
            firstname
            jobtitle
            lastname
            phone
            title
          }
          content
          featuredImage {
            node {
              altText
              imageCopyright {
                photographer
                rights
              }
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 60
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    breakpoints: [320, 480, 640, 756, 920]
                  )
                }
              }
            }
          }
          areas {
            nodes {
              id
              name
            }
          }
        }
      }
    }

    protagonists: allWpProtagonist(sort: { fields: menuOrder }) {
      edges {
        node {
          id
          title
          content
          slug
          uri
          member {
            jobtitle
          }
          featuredImage {
            node {
              altText
              imageCopyright {
                photographer
                rights
              }
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 60
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    breakpoints: [320, 480, 640, 756, 920]
                  )
                }
              }
            }
          }
        }
      }
    }

    # events: allWpEvent(sort: { fields: startDate, order: ASC }) {
    #   edges {
    #     node {
    #       id
    #       title
    #       slug
    #       uri
    #       content
    #       excerpt
    #       allDay
    #       date
    #       duration
    #       endDate
    #       protagonists {
    #         moderation {
    #           ... on WpProtagonist {
    #             id
    #             title
    #             featuredImage {
    #               node {
    #                 id
    #                 localFile {
    #                   childImageSharp {
    #                     gatsbyImageData(
    #                       quality: 60
    #                       placeholder: BLURRED
    #                       layout: FULL_WIDTH
    #                       breakpoints: [320, 480, 640, 756, 920]
    #                     )
    #                   }
    #                 }
    #               }
    #             }
    #           }
    #         }
    #         together {
    #           ... on WpProtagonist {
    #             id
    #             title
    #             featuredImage {
    #               node {
    #                 id
    #                 localFile {
    #                   childImageSharp {
    #                     gatsbyImageData(
    #                       quality: 60
    #                       placeholder: BLURRED
    #                       layout: FULL_WIDTH
    #                       breakpoints: [320, 480, 640, 756, 920]
    #                     )
    #                   }
    #                 }
    #               }
    #             }
    #           }
    #         }
    #       }
    #       eventsCategories {
    #         nodes {
    #           name
    #         }
    #       }
    #       featured
    #       organizers {
    #         nodes {
    #           title
    #         }
    #       }
    #       venue {
    #         title
    #       }
    #       startDate
    #       startDates
    #       status
    #     }
    #   }
    # }
  }
`
